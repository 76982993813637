<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>
      <v-flex md12>
        <form class="text-center">
          <v-container py-0>
            <v-flex>
              <qrcode-vue
                :value="qrCode"
                :size="300"
                level="H"
                render-as="svg"
                style="
                  width: fit-content;
                  border: 1px solid;
                  padding: 10px;
                  margin: auto;
                "
              />
            </v-flex>
            <v-chip
              class="font-weight-bold"
              style="font-size: 16px">
              {{ qrCode }}
            </v-chip>
          </v-container>
        </form>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    QrcodeVue
  },
  data: () => ({
    qrCode: '',
    productName: null,
    productPoints: null
  }),
  computed: {
    ...mapGetters(['GET_PRODUCT_WITH_QR_CODE_DATA'])
  },
  watch: {
    // GET_PRODUCT_WITH_QR_CODE_DATA: function () {
    //   this.productName = this.GET_PRODUCT_WITH_QR_CODE_DATA.name
    //   this.productPoints = this.GET_PRODUCT_WITH_QR_CODE_DATA.points
    // }
  },
  mounted () {
    if (this.$route.params.qr_code) {
      this.qrCode = this.$route.params.qr_code
      // let filter = {
      //   params: {
      //     qrCode: this.qrCode
      //   }
      // }
      // this.GET_PRODUCT_WITH_QR_CODE(filter)
    }
  },
  methods: {
    ...mapActions(['GET_PRODUCT_WITH_QR_CODE'])
  }
}
</script>
